<template>
  <div class="relative">
    <div
      v-if="leadingIcon || trailingIcon"
      :class="[
        'pointer-events-none absolute inset-y-0 flex items-center',
        leadingIcon && 'left-0 pl-3',
        trailingIcon && 'right-0 pr-3',
      ]"
    >
      <FontAwesomeIcon
        :icon="(leadingIcon ?? trailingIcon) ?? ''"
        class="size-4 text-default-400"
        aria-hidden="true"
      />
    </div>
    <input
      :id="id"
      ref="input"
      v-model="model"
      :type="isValueHidden ? 'text' : type"
      :placeholder="placeholder"
      :pattern="pattern"
      :disabled="disabled"
      :class="[
        'block w-full rounded-md border-0 py-1.5 text-default-900 ring-1 ring-inset ring-gray-300',
        'placeholder:text-default-400',
        'focus:ring-2 focus:ring-inset focus:ring-indigo-600',
        'sm:text-sm sm:leading-6',
        leadingIcon && 'pl-10',
        trailingIcon && 'pr-10',
        disabled && 'disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-default-500 disabled:ring-gray-200',
      ]"
      class="block w-full rounded-md border-0 py-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
      v-bind="$attrs"
    >
    <button
      v-if="showHideIcon && !trailingIcon"
      id="toggle-hide-icon"
      :class="[
        'absolute inset-y-0 right-0 flex items-center rounded-md px-3',
        'focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600',
      ]"
      type="button"
      @click="toggleHideValue"
    >
      <FontAwesomeIcon
        :icon="faEyeSlash"
        :class="isValueHidden ? 'text-neutral-800' : 'text-gray-400'"
        class="size-5 cursor-pointer"
        aria-hidden="true"
      />
      <span class="sr-only">
        Show password
      </span>
    </button>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  onMounted,
  ref,
} from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faEyeSlash } from '@awesome.me/kit-644ec404bf/icons/classic/regular';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const props = withDefaults(defineProps<{
  disabled?: boolean;
  id?: string,
  leadingIcon?: IconDefinition;
  type?: 'email' | 'password' | 'text' | 'tel' | 'url' | 'search',
  placeholder?: string,
  pattern?: string,
  trailingIcon?: IconDefinition;
}>(), {
  type: 'text',
});

const model = defineModel<string | number | undefined | null>();

const input = ref<HTMLInputElement | null>(null);
const isValueHidden = ref<Boolean>(false);

onMounted(() => {
  if (input.value?.hasAttribute('autofocus')) {
    input.value?.focus();
  }
});

const showHideIcon = computed(() : Boolean => props.type === 'password');

const toggleHideValue = () : void => {
  isValueHidden.value = !isValueHidden.value;
};

defineExpose({ focus: () => input.value?.focus() });
</script>
