<template>
  <Transition
    :enter-active-class="`transform ease-out duration-${transitionDuration} transition`"
    enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
    :leave-active-class="`transition ease-in duration-${transitionDuration}`"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="isShowing"
      class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black/5"
      @mouseover="stop"
      @mouseleave="start"
    >
      <div class="p-base">
        <div class="flex items-start">
          <div class="shrink-0">
            <CheckCircleIcon
              v-if="toast.type === 'success'"
              class="size-5 text-emerald-600"
            />
            <XMarkIcon
              v-if="toast.type === 'error'"
              class="size-5 text-red-600"
            />
          </div>
          <div class="ml-3 w-0 flex-1">
            <p class="text-sm font-medium text-default-900">
              {{ toast.label }}
            </p>
          </div>
          <div class="ml-4 flex shrink-0">
            <button
              class="inline-flex rounded-md bg-white text-default-400 hover:text-default-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              @click="removeToast"
            >
              <span class="sr-only">Close</span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
                  fill="#9CA3AF"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import useToasts from '@/Composables/useToasts';
import { XMarkIcon, CheckCircleIcon } from '@heroicons/vue/24/solid';
import { useTimeoutFn } from '@vueuse/core';
import ToastData = AgentSoftware.SpectreToasts.Data.ToastData;

const transitionDuration = 300;
const isShowing = ref<boolean>(false);
const { duration, dequeue } = useToasts();

defineProps<{
  toast: ToastData
}>();

const removeToast = () => {
  isShowing.value = false;
  useTimeoutFn(() => dequeue(), transitionDuration);
};

const { start, stop } = useTimeoutFn(() => {
  removeToast();
}, duration - transitionDuration);

onMounted(() => {
  isShowing.value = true;
  start();
});
</script>
