<script setup>
import PrimaryButton from '@/Components/PrimaryButton.vue';
import Background from '../../../images/auth/background.png';
import Graphic from '../../../images/auth/autosocial.png';

const handleRedirect = () => {
  window.open('https://auto.social/property/spectre/', '_blank');
};

</script>

<template>
  <div class="relative m-4 ms-0 hidden w-full justify-center lg:flex">
    <article class="absolute inset-0 z-10 m-auto h-fit w-1/2 max-w-[700px] space-y-10 p-0 text-white">
      <img
        :src="Graphic"
        alt=""
      >
      <!--      <div class="w-fit px-0.5 py-0.5 bg-gradient-to-r from-[#3535D7] to-[#B113E9] rounded-3xl mx-auto">-->
      <!--        <div-->
      <!--          class="mx-auto cursor-pointer rounded-3xl bg-black px-4 py-2 text-sm"-->
      <!--            @click="handleRedirect"-->
      <!--        >-->
      <!--          Start your free trial-->
      <!--        </div>-->
      <!--      </div>-->
      <div>
        <h2 class="mx-auto w-fit text-xl font-bold leading-9 tracking-tight text-white">
          Social media marketing is here!
        </h2>
        <p class="mt-4 text-center text-gray-400">
          Exclusively partnered with Spectre, Auto.social automates your social media marketing strategy - helping you reach wider audiences, increase brand awareness, and win new business, effortlessly.
        </p>
        <p class="mt-6 text-center">
          <PrimaryButton
            width="w-fit"
            @click="handleRedirect"
          >
            Start your free trial
          </PrimaryButton>
        </p>
      </div>
    </article>
    <img
      class="size-full self-end rounded-xl object-cover"
      :src="Background"
      alt=""
    >
  </div>
</template>
