<template>
  <article
    id="policy"
    class="w-full text-center text-xs text-gray-500"
  >
    By signing into Spectre.uk.com, I agree to the <a
      id="privacy-policy"
      class="font-bold hover:underline"
      href="https://spectre.uk.com/privacy"
    >Privacy Policy</a> and
    <a
      id="terms-of-service"
      class="font-bold hover:underline"
      href="https://spectre.uk.com/terms"
    >Terms of service</a>.
  </article>
</template>
