<template>
  <Component
    :is="$attrs.href ? Link : 'button'"
    class="inline-flex items-center rounded-md border border-gray-300 bg-white text-gray-700 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25"
    :class="sizeClass"
  >
    <slot />
  </Component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Link } from '@inertiajs/vue3';

const props = withDefaults(
  defineProps<{
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  }>(),
  {
    size: 'md',
  },
);

const sizeClass = computed(() => ({
  xs: 'px-2 py-1 text-xs',
  sm: 'px-2 py-1 text-sm',
  md: 'px-2.5 py-1.5 text-sm',
  lg: 'px-3 py-2 text-sm',
  xl: 'px-3.5 py-2.5 text-sm',
}[props.size]));
</script>
