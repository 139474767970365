<template>
  <div
    v-if="queue.length"
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 z-[60] flex items-end px-4 py-6 sm:items-start sm:p-6"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <Toast
        v-for="message in queue"
        :key="message.label"
        :toast="message"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Toast from '@/Components/Toast.vue';
import useToasts from '@/Composables/useToasts';

const { queue } = useToasts();
</script>
