<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import SecondaryButton from '@/Components/SecondaryButton.vue';
import Policy from '@/Components/Auth/Policy.vue';
import AuthPageContainerLayout from '@/Layouts/Auth/AuthPageContainerLayout.vue';
import useAuth from '@/Composables/useAuth';
import InputError from '@/Components/InputError.vue';
import {
  Head, Link, router, usePage,
} from '@inertiajs/vue3';
import TextInput from '@/Components/TextInput.vue';
import InputLabel from '@/Components/InputLabel.vue';
import AuthBanner from '@/Components/Auth/AuthBanner.vue';

const { signInForm: form } = useAuth();
const processing = ref(false);

const props = defineProps<{
  canResetPassword?: boolean;
  status?: string;
  email?: string;
  originUrl?: string;
}>();

onBeforeMount(() => {
  form.email = props.email || '';
});

const isProduction = computed(() : boolean => usePage().props.environment === 'production');

const submit = () => {
  form.post(route('login'), {
    onStart: () => {
      processing.value = true;
    },
    onError: () => {
      processing.value = false;
      form.reset('password');
    },
  });
};

</script>

<template>
  <Head title="Login" />
  <AuthPageContainerLayout>
    <div class="flex w-full flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none xl:w-1/3">
      <div class="mx-auto w-full max-w-sm">
        <h2 class="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign into Spectre
        </h2>

        <form
          class="mt-10 space-y-6"
          @submit.prevent="submit"
        >
          <div class="space-y-2">
            <InputLabel for="login-email-input">
              Email address
            </InputLabel>
            <div v-if="originUrl && email">
              <p
                id="login-email-input"
                class="w-full p-0 py-1.5 sm:text-sm sm:leading-6"
              >
                {{ form.email }}
              </p>
              <a
                id="redirect-to-origin"
                :href="originUrl"
                class="w-fit cursor-pointer text-xs font-semibold text-primary-600 underline"
              >
                Use another account
              </a>
            </div>
            <TextInput
              v-else
              id="login-email-input"
              v-model="form.email"
              type="email"
              placeholder="e.g. email@example.com"
              required
              autofocus
            />
            <InputError :message="form.errors.email" />
          </div>

          <div class="space-y-2">
            <InputLabel for="login-password-input">
              Password
            </InputLabel>
            <TextInput
              id="login-password-input"
              v-model="form.password"
              type="password"
              :disabled="processing"
              required
            />
            <InputError :message="form.errors.password" />
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <input
                id="remember-me"
                v-model="form.remember"
                name="remember-me"
                type="checkbox"
                class="size-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600"
              >
              <label
                for="remember-me"
                class="ml-3 block text-sm leading-6 text-gray-700"
              >
                Remember me
              </label>
            </div>

            <div class="text-sm leading-6">
              <Link
                v-if="canResetPassword"
                id="forgot-password-link"
                :href="route('password.request')"
                class="font-semibold text-neutral-800 hover:underline"
              >
                Forgot password?
              </Link>
            </div>
          </div>

          <PrimaryButton
            id="login-button"
            :class="{ 'opacity-25': form.processing }"
            class="flex w-full justify-center"
            :disabled="processing"
          >
            {{ processing ? 'Signing You In' : 'Sign In' }}
          </PrimaryButton>
        </form>
        <div class="mt-4">
          <div v-if="!isProduction">
            <div class="relative">
              <div
                class="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div class="w-full border-t border-gray-200" />
              </div>
              <div class="relative flex justify-center text-sm font-medium leading-6">
                <span class="bg-white px-6 text-sm font-normal text-gray-500">Or</span>
              </div>
            </div>

            <div class="mt-4">
              <SecondaryButton
                id="register-button"
                class="flex w-full justify-center"
                @click="() => router.visit(route('register'))"
              >
                Register
              </SecondaryButton>
            </div>
          </div>

          <Policy class="mx-auto mt-4 w-9/12" />
        </div>
      </div>
    </div>
    <AuthBanner />
  </AuthPageContainerLayout>
</template>
