import { useForm, router } from '@inertiajs/vue3';
import { SignInForm, RegisterForm } from '@/types/auth-types';

export default function useAuth() {
  const logout = () => router.visit(route('logout'), { method: 'post' });
  const signInForm = useForm<SignInForm>({
    email: '',
    password: '',
    remember: false,
  });

  const registerForm = useForm<RegisterForm>({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
  });

  return {
    signInForm,
    registerForm,
    logout,
  };
}
